// 待废除 设备信息
<template>

    <div class='deviceDetails baseBg'>
        <backUp></backUp>
        <!-- 头部搜索 -->
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     ref="searchForm"
                     label-width="80px"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="设备编号">
                    <el-input v-model="searchForm.qrCode"
                              placeholder="请输入设备编号"
                              clearable></el-input>
                </el-form-item>
                <el-button type="primary"
                           @click="getDeviceListFromId">搜索</el-button>
            </el-form>
        </div>
        <div class="searchBar">
            <p>{{deviceName}}{{' / '+provider}}{{' / '+empTypeModel}} <span class="count"
                      style="margin-left: 30px">剩余设备：{{count}}</span></p>
        </div>

        <!-- 表格 -->
        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData.data"
                          border
                          stripe>
                    <el-table-column v-for="col in tableData.columns"
                                     :prop="col.id"
                                     :key="col.id"
                                     :label="col.label"
                                     :min-width="col.width">
                    </el-table-column>
                    <el-table-column label="操作"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row)">查看</el-link>
                        </template>
                    </el-table-column>
                </el-table>

            </div>
        </div>

    </div>

</template>

<script>
import { Loading } from "element-ui";
import baseinfoManage from "@/api/baseinfoManage.js";
import backUp from "@/components/backUp";
export default {
    name: "deviceDetails",

    props: [],

    components: { backUp },

    data() {
        return {
            searchForm: { empId: "" },
            deviceName: "", // 设备名称
            count: "", // 剩余数量
            provider: "", // 供应商
            empTypeModel: "", // 规格型号
            tableData: {
                columns: [
                    { id: "qrCode", label: "设备编号", width: "150" },
                    { id: "createTime", label: "入库时间", width: "150" },
                    { id: "operatorName", label: "入库人", width: "150" },
                    { id: "flowCode", label: "入库记录", width: "150" },
                ],
                data: [],
            },
        };
    },

    created() {},

    mounted() {
        this.deviceName = this.$route.query.deviceName;
        this.count = this.$route.query.count;
        this.provider = this.$route.query.provider; // 供应商
        this.empTypeModel = this.$route.query.empTypeModel; // 规格型号
        // 获取设备详情列表
        this.getDeviceListFromId();
    },

    methods: {
        // 获取设备详情列表
        getDeviceListFromId() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            baseinfoManage
                .findByOrderAndModel({
                    purchaseViewId: this.$route.query.purchaseViewId,
                    modelId: this.$route.query.modelId,
                    qrCode: this.searchForm.qrCode,
                })
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content;
                    // this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 查看设备信息
        details(item) {
            this.$router.push({
                path: "/stockManage/deviceInoutRecord",
                query: { modelId: item.modelId, empId: item.id },
            });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
