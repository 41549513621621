// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const baseInfoManage = {
    // 分页获取供应商信息列表
    getProviderList(params) {
        return get("/baseinfo/emp-provider/list", params);
    },

    // 新增供应商信息
    saveProvider(params) {
        return postJson("/baseinfo/emp-provider/save", params);
    },

    // 编辑供应商信息
    updateProvider(params) {
        return postJson("/baseinfo/emp-provider/update", params);
    },

    // 删除供应商信息
    deleteProvider(id) {
        return get(`/baseinfo/emp-provider/delete/${id}`);
    },

    // 获取设备信息列表
    getDeviceList(params) {
        return postJson("/emp/type-model/table", params);
    },

    // 获取采购记录列表
    getTableByProvider(params) {
        return postJson("/emp/purchase-order/tableByProvider", params);
    },

    // 根据条件查询设备列表
    getDeviceInfoTable(params) {
        return postJson("/emp/device-info/table", params);
    },

    // 根据采购单、设备型号获取实际入库设备详情
    findByOrderAndModel(params) {
        return get("/emp/flow-item/findByOrderAndModel", params);
    },

    // 合同进度图
    contractEchart() {
        return postJson("/emp/device-info/table");
    },
    // 查询供应商|客户的数量
    getUserTreeCount(type) {
        return get(`/baseinfo/company/countByType/${type}`);
    },

    // 设备部门变更记录
    getDeviceDeptChangeHistory(params) {
        return postJson("/emp/device-dept-change-log/findList", params);
    },
    // 合并供应商/客户
    mergeCompany(params) {
        return postJson("/baseinfo/company/mergeCompany", params);
    },
};

export default baseInfoManage;
